<template>
  <div class="dialog-preview" @click="openChat">
    <div class="dialog-preview__ava-wrapper">
<!--      <img :src="img" alt="" class="dialog-preview__ava">-->
      <a-avatar :src="userForChatAvatar" class="dialog-preview__ava">
        <a-icon slot="icon" type="user"/>
      </a-avatar>
    </div>
    <div class="dialog-preview__info">
      <div class="dialog-preview__name-date">
        <p class="dialog-preview__name">{{ ourContactPerson.name }}</p>
        <p class="dialog-preview__date">{{ date }}</p>
      </div>
      <p class="dialog-preview__last-message">{{ message }}</p>
    </div>
    <span class="dialog-preview__new-message" v-if="isNewMessage"></span>
  </div>
</template>

<script>
import moment from 'moment-timezone'

  export default {
    props: {
      dialog: {
        required: true
      }
    },
    computed: {
      userForChatAvatar() {
        return this.ourContactPerson?.photo
      },
      isMessageFromOurUser() {
        return this.ourUserId === this.dialog.from_user_id
      },
      ourContactPerson() {
        if (this.isMessageFromOurUser) return this.dialog.to_user
        else return this.dialog.from_user
      },
      message() {
        return this.dialog.message
      },
      date() {
        return moment(this.dialog.updated_at).format('MMM DD, YYYY')
      },
      isNewMessage() {
        return !this.isMessageFromOurUser && !this.dialog.is_read
      },
      ourUserId() {
        return this.$store.state.auth.user.id
      }
    },
    methods: {
      openChat() {
        this.$router.push(`/chat/${this.ourContactPerson.id}`)
      }
    }
  }
</script>

<style lang="stylus" scoped>
.dialog-preview {
  display flex
  align-items center
  justify-content: space-between
  width 100%
  margin-bottom 16px

  &__ava-wrapper {
    width 32px
    height 32px
    min-width 32px
    border-radius: 20px;
    background: #1d2130;
    overflow hidden
    margin-right 8px
  }

  &__ava {
    width 100%
    margin-top 2px
  }

  &__info {
    width 100%
  }

  &__name-date {
    display flex
    justify-content: space-between
    width 100%
  }

  &__name {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    color: #D8D8D8;
    margin-bottom 0
  }

  &__date {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #7B8B97;
    margin-bottom 0
  }

  &__last-message {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: #7B8B97;
    margin-bottom 0
  }

  &__new-message {
    width 8px
    height 8px
    min-width 8px
    background-color #4285F4
    border-radius: 50%;
    margin-left 8px
  }
}
</style>
