<template>
  <view-container name="chat">
    <view-header>
      <header-competitions class="view-header">Chat</header-competitions>
    </view-header>

    <view-content>
      <!--<ChoosenDialogs />-->
      <!--<a-divider />-->
      <DialogPreview
          v-for="dialog in dialogs"
          :key="dialog.id"
          :dialog="dialog"
      />
<!--      <Ad/>-->
      <!--      <DialogPreview :img="require('@/assets/images/member2.png')" :name="'Cody Fisher'" :date="'Mar 01, 2021'" :lastMessage="'Digital product design news, articles, an...'" :newMessage="true" />-->
      <!--      <DialogPreview :img="require('@/assets/images/member3.png')" :name="'Annette Black'" :date="'Mar 01, 2021'" :lastMessage="'Digital product design news, articles, and...'" />-->
      <!--      <DialogPreview :img="require('@/assets/images/member4.png')" :name="'Marvin McKinney'" :date="'Mar 01, 2021'" :lastMessage="'Digital product design news, articles, and...'" />-->
      <!--      <DialogPreview :img="require('@/assets/images/member5.png')" :name="'Josh'" :date="'Mar 01, 2021'" :lastMessage="'Digital product design news, articles, and...'" />-->
      <!--      <DialogPreview :img="require('@/assets/images/member1.png')" :name="'Darlene Robertson'" :date="'Mar 01, 2021'" :lastMessage="'I want to talk about the hard stuff people...'" />-->
      <!--      <DialogPreview :img="require('@/assets/images/member2.png')" :name="'Cody Fisher'" :date="'Mar 01, 2021'" :lastMessage="'Digital product design news, articles, and...'" />-->
      <!--      <DialogPreview :img="require('@/assets/images/member3.png')" :name="'Annette Black'" :date="'Mar 01, 2021'" :lastMessage="'Digital product design news, articles, and...'" />-->
      <!--      <DialogPreview :img="require('@/assets/images/member4.png')" :name="'Marvin McKinney'" :date="'Mar 01, 2021'" :lastMessage="'Digital product design news, articles, and...'" />-->
      <!--      <DialogPreview :img="require('@/assets/images/member5.png')" :name="'Josh'" :date="'Mar 01, 2021'" :lastMessage="'Digital product design news, articles, and...'" />-->
      <FloatButton icon="new-chat" @click="openNewMessages" />
    </view-content>

    <view-footer>
      <footer-competitions class="view-footer"/>
    </view-footer>
  </view-container>
</template>

<script>
// import Ad from '@/components/Ad'
import FloatButton from '@/components/FloatButton'
// import ChoosenDialogs from '@/components/ChoosenDialogs'
import DialogPreview from '@/components/DialogPreview'
import { mapState } from 'vuex'

export default {
  name: 'Chat',
  components: {
    // Ad,
    FloatButton,
    // ChoosenDialogs,
    DialogPreview,
  },
  methods: {
    openNewMessages() {
      this.$router.push({ name: 'new-message' })
    }
  },
  computed: {
    ...mapState({
      dialogs: state => state.chat.dialogs
    })
  },
  mounted() {
    this.$store.dispatch('loadDialogs')
  }
}
</script>
