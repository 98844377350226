<template>
  <a-button @click="click" class="float-button">
    <Icon :size="24" :type="icon"/>
  </a-button>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  name: 'FloatButton',
  components: {
    Icon
  },
  props: {
    icon: {
      type: String,
      default: 'plus'
    }
  },
  methods: {
    click () {
      this.$emit('click')
    }
  }
}
</script>